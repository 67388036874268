import React, { useEffect, useState, useContext } from "react"
import { graphql } from "gatsby"

import "../../../styles/pages/sunglasses.scss"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import GlassesHeading from "../../../components/GlassesHeading"
import Breadcrumbs from "../../../components/Breadcrumbs"
import GlassesFilter from "../../../components/GlassesFilter"
import GlassesList from "../../../components/GlassesList"
import ProductBanner from "../../../components/ProductBanner"
import { useDebounce, useGlasses } from "../../../services/hooks"
import CartContext from "../../../context/cartContext"

const Sunglasses = ({ data, location }) => {
  const hubbleFrames = data.hubbleapi.hubbleFrameProducts.filter(
    ({ collectionString, sunglassesAvailable }) => {
      const collectionArray = collectionString.split(",")

      return (
        collectionArray.includes("Hubble") &&
        !collectionArray.includes("Modern") &&
        sunglassesAvailable
      )
    }
  )

  const right_side_text =
  "Look good and see better with stylish prescription sunglasses from Hubble. People with heart-shaped faces are falling in love with our trendy collection. Try round frames that balance the pointed areas of the face or Aviators that balance the wider top half. Or how about oval frames for adding length to the face while balancing the chin and hairline?<br><br>No matter what you choose, you can’t go wrong with sunglasses from Hubble for heart-shaped faces. Your perfect pair is waiting for you! Plus, you have the option to add a prescription to any pair of our handcrafted sunglasses."
  const list_items = [
  "Premium, hand-polished acetate frames sourced from natural fibers. Every pair of our high-quality sunglasses feature impact-resistant lenses for maximum durability and are designed to deliver supreme comfort.",
  "Option to add your prescription to all sunglasses and enjoy both vision clarity and comfort all in one piece of eyewear.",
  "All of our sunglasses for heart shaped faces come with polarized lenses that block 99% of UVA/UVB rays and eliminate daytime glare.",
  "Affordable polarized sunglasses lenses.",
  "Hand-assembled in house and made to order.",
  ]

  const { setHeaderColor } = useContext(CartContext)

  const defaultFilter = {
    price: [],
    width: ["heart"],
    shape: [],
    material: [],
    color: [],
    gender: [],
  }

  const params = new URLSearchParams(location.search)

  params.forEach((value, key) => {
    defaultFilter[key] = value.split(",")
  })

  const [filter, setFilter] = useState(defaultFilter)

  const debouncedFilter = useDebounce(filter, 500)

  useEffect(() => {
    const params = new URLSearchParams(location.search)

    params.forEach((value, key) => {
      defaultFilter[key] = value.split(",")
    })

    setFilter(defaultFilter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  useEffect(() => {
    setHeaderColor("green")

    return () => {
      setHeaderColor("black")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sortedGlasses = useGlasses(hubbleFrames, debouncedFilter)

  useEffect(() => {
    if (typeof window !== undefined ) {
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: "view_item_list",
        ecommerce: {
          items: sortedGlasses.map(( product, index ) => (
            {
              index: index,
              item_id: product.shopifyProductId,
              item_name: product.title,
              currency: "USD",
              discount: 0,
              item_brand: "Hubble",
              item_category: "frames",
              item_category2: 'sunglasses',
              item_category3: product.productGender,
              item_category4: product.material,
              item_category5: product.size,
              item_list_id: `${product.productGender}-Sunglasses-By-Face-Heart`,
              price: parseFloat(product.glassesBasePrice),
            }
          ))
        }
      })
    }
  }, [sortedGlasses])

  const schemaMarkup = [
    {
      "@context": "https://schema.org",
      "@url": "https://hubblecontacts.com/shop-by-glasses-face-shape/glasses-heart-face-shape",
      "@type": "webpage",
      "name": "Shop Glasses and Sunglasses for Heart Face Shapes | Hubble",
      "description": "Discover the best glasses and sunglasses for heart face shapes. We make finding the perfect pair of glasses for heart shaped faces easy with our wide selection of frames available in a variety of styles.",
      "publisher": {
      "@type": "Organization",
      "name": "Hubble Contacts"
      }
    }
  ]

  return (
    <Layout>
      <SEO title="Shop Sunlasses for Heart Face Shapes"
      description="Discover the best sunglasses for heart face shapes. We make finding the perfect pair of glasses for heart shaped faces easy with our wide selection of frames available in a variety of styles."
      schemaMarkup={schemaMarkup}
      />
      <div className="sunglasses-content">
        <GlassesHeading
          color="green"
          title="Shop Sunglasses for a Heart Shaped Face"
          description="Shine in the sun with the best sunglasses for a heart shaped face from
            Hubble, starting at just $59.99."
        />

        <div className="glasses-container">
          <div className="grid-section">
            <Breadcrumbs
              color="green"
              links={[{ to: "/", label: "Homepage" }, { label: "Sunglasses" }]}
            />
            <GlassesFilter
              type="sunglasses"
              glassesLength={sortedGlasses.length}
              filter={filter}
              setFilter={setFilter}
            />
            <GlassesList
              type="sunglasses"
              glasses={sortedGlasses}
              color={filter.color}
              gender={filter.gender[0]}
            />
            <ProductBanner
              title={"Handcrafted sunglasses for heart shaped faces."}
              right_side_text={right_side_text}
              list_items={list_items}
              optional_image="Hubble-Sunglasses-PCP-Inset-Lifestyle-Image.jpg"
              color="green"
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Sunglasses

export const query = graphql`
  query {
    hubbleapi {
      hubbleFrameProducts {
        title
        handle
        shopifyProductId
        description
        collectionString
        glassesBasePrice
        sunglassesBasePrice
        sunglassesAvailable
        sunglassesMaleHeaderImage1
        sunglassesFemaleHeaderImage1
        productGender
        maleHeaderImage1
        maleHeaderImage2
        size
        shape
        femaleHeaderImage1
        femaleHeaderImage2
        productImage
        material
        materialFilter
        productClothImage
        sortOrder
        relatedFrames {
          edges {
            node {
              title
              productImage
              hubbleFrameVariants {
                title
                color
                colorFilter
                heroColor
                productImage1
                productImage2
                sunglassesWideImage1
                sunglassesWideImage2
                sunglassesWideImage3
                sunglassesProductImage1
                sunglassesProductImage2
                sunglassesProductImage3
                sunglassesProductImage4
              }
            }
          }
        }
        hubbleFrameVariants {
          title
          shopifyVariantId
          color
          colorFilter
          sku
          heroColor
          availableForSale
          productImage1
          productImage2
          productImage3
          sunglassesWideImage1
          sunglassesWideImage2
          sunglassesWideImage3
          sunglassesProductImage1
          sunglassesProductImage2
          sunglassesProductImage3
          sunglassesProductImage4
        }
      }
    }
  }
`
